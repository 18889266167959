<template>
    <div class="home">
        <Header />
        <div class="content">
            <van-tabs v-model="activeName" swipeable  style="flex-basis: 24%;">
                <van-tab class="title title_color" style="flex-basis: 24%;" :title="item.name"  v-for="(item,index) in playMethodList" :key="index" >
                    <div class="item van-bgcolor-white">
                        <img :src="item.img" alt="">
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <Tabs />
        <FootNav  :activeIndex="active"/>
    </div>
  </template>
  
  <script>
  import Header from "../components/Header.vue";
  import FootNav from "../components/FootNav.vue";

  export default {
    components: {
      Header,
      FootNav
    },
    data() {
        return {
            active:1,
            activeName:0,
            icon: {
                active: 'https://img.yzcdn.cn/vant/user-active.png',
                inactive: 'https://img.yzcdn.cn/vant/user-inactive.png'
            },
            playMethodList:[]
        };
    },
    created() {
        this.getPlayMethodList()
    },
    methods: {
        getPlayMethodList(){
            this.service.post('Home/playMethod')
                .then((res) => {
                    console.log(res)
                this.playMethodList = res.data.data
            }) 
        },
    }
  };
  
  </script>
  
  <style>
.content{
    width: 100%;
    padding: 0px 10px;
    margin-bottom: 75px;
}
.item{
    margin-top: 25px;
    border: 1px solid #F7E3BA;
    background: none;
    border-radius: 16px;
    padding: 5px;
}
.van-tabs__wrap--scrollable .van-tab{
    flex-basis: 24%!important;
    flex:0 0 24%!important;
}
.item .van-tab{
    line-height: 50px;
}
.van-tabs__wrap--scrollable .van-tabs__nav{
    padding-left: 0px;
}
.title{
    background: none;
}

.van-ellipsis{
    white-space:visible;
    overflow:visible;
    color: #f4cabd;
}
.van-tab--active .van-ellipsis{
    color:#fff;
    font-weight: bolder;
    font-size: 16px;
}
.van-tabs__nav{
    background: none;
}
.van-tabs__wrap--scrollable .cover{
    background: none;
}

.van-tabs__line{
    background-color: #FFF9A9;
}
.item img{
    width: 100%;
    height: 100%;
    border-radius: 16px;
}
.van-tab__gap{
    display: none;
}
.van-hairline--bottom::after{
    border-bottom-width:0px;
}
</style>
  